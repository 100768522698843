import React, { useEffect, useState } from 'react';
import { Edit2, Home, Image, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Button as Button2, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';
import { randomColor } from '../../components/general';
import { Helmet } from 'react-helmet';
import { createImageGroup, deleteImageGroup, getAllImageGroups, getImageGroup, updateImageGroup } from '../../core/request';

const ImageGroupPage = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({ id: null, TitleTR: "", TitleEN: "", DescriptionTR: "", DescriptionEN: "", ContentTR: "", ContentEN: "", Field1TR: "", Field1EN: "", Field2TR: "", Field2EN: "", Field3TR: "", Field3EN: "", Field4TR: "", Field4EN: "", Field5TR: "", Field5EN: "" })

    const clearForm = () => {
        setFormData({ id: null, TitleTR: "", TitleEN: "", DescriptionTR: "", DescriptionEN: "", ContentTR: "", ContentEN: "", Field1TR: "", Field1EN: "", Field2TR: "", Field2EN: "", Field3TR: "", Field3EN: "", Field4TR: "", Field4EN: "", Field5TR: "", Field5EN: "" })
        setSelectedData(null)
    }

    const getDatas = async () => {
        const imageGroups = await getAllImageGroups();
        if (imageGroups.message === "Success")
            setData(imageGroups.result);

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const getData = async (id) => {
        const imageGroup = await getImageGroup(id);
        setSelectedData(id)
        setFormData({ TitleTR: imageGroup.result.titleTR, TitleEN: imageGroup.result.titleEN, DescriptionTR: imageGroup.result.descriptionTR, DescriptionEN: imageGroup.result.descriptionEN, ContentTR: imageGroup.result.contentTR, ContentEN: imageGroup.result.contentEN, Field1TR: imageGroup.result.field1TR, Field1EN: imageGroup.result.field1EN, Field2TR: imageGroup.result.field2TR, Field2EN: imageGroup.result.field2EN, Field3TR: imageGroup.result.field3TR, Field3EN: imageGroup.result.field3EN, Field4TR: imageGroup.result.field4TR, Field4EN: imageGroup.result.field4EN, Field5TR: imageGroup.result.field5TR, Field5EN: imageGroup.result.field5EN })
    }

    const addData = async () => {
        let res;
        if (selectedData) {
            res = await updateImageGroup(selectedData, formData.TitleTR, formData.TitleEN, formData.DescriptionTR, formData.DescriptionEN, formData.ContentTR, formData.ContentEN, formData.Field1TR, formData.Field1EN, formData.Field2TR, formData.Field2EN, formData.Field3TR, formData.Field3EN, formData.Field4TR, formData.Field4EN, formData.Field5TR, formData.Field5EN);
        } else {
            res = await createImageGroup(formData.TitleTR, formData.TitleEN, formData.DescriptionTR, formData.DescriptionEN, formData.ContentTR, formData.ContentEN, formData.Field1TR, formData.Field1EN, formData.Field2TR, formData.Field2EN, formData.Field3TR, formData.Field3EN, formData.Field4TR, formData.Field4EN, formData.Field5TR, formData.Field5EN);
        }
        if (res.message === "Success")
            toast.success("Resim grubu içeriği eklendi.")
        else
            toast.error("Resim grubu içeriği eklenirken bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const deleteData = async (id) => {
        const imageGroup = await deleteImageGroup(id);
        if (imageGroup.message === "Success")
            toast.success("Resim grubu içeriği silindi.")
        else
            toast.error("Resim grubu içeriği silinirken bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "",
            key: "imageGroupID",
            render: (e) => <div className='table-logo shadow' style={{ backgroundColor: randomColor() }}>{e.titleTR[0]}</div>,
            width: 50
        },
        {
            title: "ID",
            key: "imageGroupID",
            dataIndex: "imageGroupID"
        },
        {
            title: "Başlık",
            key: "titleTR",
            render: (e) => e.titleTR
        },
        {
            title: "Açıklama",
            key: "descriptionTR",
            render: (e) => e.descriptionTR
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Tooltip title="Resim Grubu Elemanları">
                            <Button2 className='border-info text-info' icon={<Image size={20} className='text-info' />} onClick={() => navigate(`/image/${e.imageGroupID}`)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Tooltip title="Resim Grubu Düzenle">
                            <Button2 className='border-warning text-warning' icon={<Edit2 size={20} className='text-warning' />} onClick={() => getData(e.imageGroupID)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu resim grubunu içeriğini silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.imageGroupID)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Tooltip title="Resim Grubu Sil">
                                <Button2 icon={<Trash2 size={20} className='text-danger' />} danger />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Helmet>
                <title>Harvestwe - Resim Grupları</title>
            </Helmet>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Resim Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Resim Grubu Ekle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={6} className='mb-3'>
                                        <span>Başlık</span>
                                        <Input type='input' value={formData.TitleTR} onChange={e => setFormData({ ...formData, TitleTR: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Başlık (EN)</span>
                                        <Input type='input' value={formData.TitleEN} onChange={e => setFormData({ ...formData, TitleEN: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Açıklama</span>
                                        <Input type='input' value={formData.DescriptionTR} onChange={e => setFormData({ ...formData, DescriptionTR: e.target.value })} />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Açıklama (EN)</span>
                                        <Input type='input' value={formData.DescriptionEN} onChange={e => setFormData({ ...formData, DescriptionEN: e.target.value })} />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {selectedData ? <Button color='light' className='border-dark mr-2' onClick={() => clearForm()}>Temizle</Button> : null}
                                        <Button
                                            color={selectedData ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{selectedData ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Resim Grubu Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='imageGroupID'
                                    dataSource={data}
                                    columns={columns}
                                    scroll={{ x: 'max-content' }}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default ImageGroupPage;
